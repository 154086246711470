export const tiktokEvent = (
  id,
  event,
  options = {},
  eventID = null,
  email = null
) => {
  try {
    if (!!window.ttq && id) {
      const isInitialized = (window.ttq.instances() || []).find(
        (pixel) => pixel.pixelCode === id
      )

      if (!isInitialized) {
        window.ttq.load(id)
      }

      if (email) {
        window.ttq.instance(id).identify({ email })
      }

      if (eventID) {
        window.ttq.instance(id).track(event, options, {
          event_id: `TikTok.${event}.${eventID}`
        })
      } else {
        window.ttq.instance(id).track(event, options)
      }
    }
  } catch (err) {
    console.log(err)
    setTimeout(() => tiktokEvent(id, event, options), 1000)
  }
}
